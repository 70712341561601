import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

import { Col, Row, Icon, Uploader } from 'vant';

Vue.use(Col);
Vue.use(Row)
Vue.use(Icon)
Vue.use(Uploader)

Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App)
}).$mount('#app')