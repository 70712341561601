import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import('@/views/index.vue')
}, {
  path: '/photo',
  name: 'Photo',
  component: () => import('@/views/photo.vue')
}
]

const router = new VueRouter({
  // mode: 'history',
  // base:'/photo/',  
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router